import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Interactions"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Icons"
        tierThree="Interactions"
      />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          These icons are strictly used for interactions made within Hudl and
          external sources.
        </Paragraph>
        <DontDo
          dontText="use interaction icons when their functions can’t be taken."
          doText="keep them closely tied to the function they enable."
          imgFilename="icons-interaction"
        />
        <DontDo
          dontText="use an icon different than its metaphor."
          doText="keep destructive functions clear."
          imgFilename="icons-interaction-metaphor"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconInteractionsDesign($title: String = "Interactions") {
    ...iconDataDesign
  }
`;
